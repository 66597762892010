import { hasFlag } from "country-flag-icons";
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import phyrexianMana from '../assets/img/manabp.png'

interface FlagCodeProps {
    flagCode: string | null
    language: string | null
}


export function Flag(flagCodeProps: FlagCodeProps) {
    const flagCode = flagCodeProps.flagCode;
    const language = flagCodeProps.language;

    return (flagCode && (flagCode !== "PH" && flagCode !== "GLOBAL") && hasFlag(flagCode) && <span className="Matchup-inline-flag" title={language || ''}>{getUnicodeFlagIcon(flagCode)}</span>)
    || (flagCode === "PH" && <img className="Matchup-inline-flag" src={phyrexianMana} title="ph" alt="Phyrexian Mana Symbol, so spooky" />)
    || (flagCode === "GLOBAL" &&  <span className="Matchup-inline-flag" title={flagCode}>🌍</span>)
    || null;
}