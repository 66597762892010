import './App.css';
import { Link, Outlet, useLocation } from 'react-router-dom';
import construction from './assets/img/construction.gif'
import { Ad } from './components/Ad';

function App() {
  const location = useLocation();
  return (
    <div className="App">
      <header className="App-header">

        <div className="App-header-masthead">
          {location.pathname !== '/' && <Link className="Back-link" to="/">← Back</Link>}
          <h1>bestbasicland.com</h1>
          <div className="App-header-construction-wrapper">
            <img src={construction} alt="Under construction (since 2018)!" />
          </div>
          {location.pathname !== '/stats' && <Link className="Stats-link" to="/stats">Stats →</Link>}
        </div>

        <Ad />

      </header>
      <main>
        <Outlet />
      </main>
      <footer>
        <p>
        Wizards of the Coast, Magic: The Gathering, and their logos are trademarks of Wizards of the Coast LLC in the United States and other countries. © 1993-{new Date().getFullYear()} Wizards. All Rights Reserved.
        <br />
        <br />
	      <a href="/">bestbasicland.com</a> and its proprietors are not affiliated with, endorsed, sponsored, or specifically approved by Wizards of the Coast LLC. <a href="/">bestbasicland.com</a> and its proprietors may use the trademarks and other intellectual property of Wizards of the Coast LLC, which is permitted under Wizards' Fan Site Policy. MAGIC: THE GATHERING® is a trademark of Wizards of the Coast. For more information about Wizards of the Coast or any of Wizards' trademarks or other intellectual property, please visit <a href="https://company.wizards.com">their website</a>.
        </p>
      </footer>
    </div>
  );
}

export default App;
