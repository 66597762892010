import './Stats.css'

import { Await, useLoaderData, useSearchParams } from "react-router-dom";
import { Stats } from "../components/api/types";
import { ChangeEvent, useState } from "react";
import { GridView } from '../components/stats/GridView';
import { GraphView } from '../components/stats/GraphView';
import { ReactComponent as W } from '../assets/img/manaw.svg'
import { ReactComponent as U } from '../assets/img/manau.svg'
import { ReactComponent as B } from '../assets/img/manab.svg'
import { ReactComponent as R } from '../assets/img/manar.svg'
import { ReactComponent as G } from '../assets/img/manag.svg'
import { ReactComponent as _1 } from '../assets/img/mana1.svg'
import { ReactComponent as C } from '../assets/img/manac.svg'

import React from 'react';
import { LoadingBlocker } from './Matchup';
import { StatsViewSelector } from '../components/stats/StatsViewSelector';

export enum StatsView {
  Grid = 'Grid',
  Graph = 'Graph',
}

export function StatsRoute({ initialView }: { initialView: StatsView }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [view, setView] = useState(initialView);
  const [color, setColor] = useState(searchParams.get('color') || "all");

  const data = useLoaderData() as {stats: Stats}; // TODO hate this cast

  function setColorFromEvent(event: ChangeEvent<HTMLInputElement>): void {
    setColor(event.currentTarget.value);
    setSearchParams({"color": event.currentTarget.value});
  }

  function renderSwitch(view: StatsView, stats: Stats): React.ReactNode | null {
    switch(view) {
      case StatsView.Graph:
        return <GraphView stats={stats} color={color} />;
      default:
        return <GridView stats={stats} color={color} />;
    }
  }

  function svgForColor(radioButtonColor: string): React.ReactNode {
    switch(radioButtonColor) {
      case "W":
        return <W />
      case "U":
        return <U />
      case "B":
        return <B />
      case "R":
        return <R />
      case "G":
        return <G />
      case "C":
        return <C />
      default:
        return <_1 />
    }
  }

  function colorRadioButton(radioButtonColor: string): React.ReactNode {
    return (
      <div key={`color-select-${radioButtonColor}`} className="Stats-color-picker">
        <label>
          <input type="radio" name="color" value={radioButtonColor} checked={color === radioButtonColor} onChange={e => setColorFromEvent(e)} />
          {svgForColor(radioButtonColor)}
        </label>
      </div>
    )
  }

  return (
    <div>
      <div className="Stats-form">
        <div className="Stats-form-color-selector-wrapper">
          {
            ["all", "W", "U", "B", "R", "G", "C"].map(colorRadioButton)
          }
        </div>
        <StatsViewSelector currentView={view} setView={setView} />
      </div>
      <div className="Stats-outer">
        <React.Suspense fallback={<LoadingBlocker />}>
          <Await resolve={data.stats}>
            {stats => renderSwitch(view, stats)}
          </Await>
        </React.Suspense>
      </div>
    </div>
  );
}