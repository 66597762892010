import { Dispatch, SetStateAction } from "react";
import { StatsView } from "../../routes/Stats";
import { ReactComponent as Graph } from '../../assets/img/graph.svg'
import { ReactComponent as Grid } from '../../assets/img/grid.svg'
import { ReactComponent as List } from '../../assets/img/list.svg'


export function StatsViewSelector({ currentView, setView }: {currentView: StatsView, setView: Dispatch<SetStateAction<StatsView>>}) {

    function svgForView(view: StatsView) {
        switch(view) {
            case StatsView.Grid:
                return <Grid />
            case StatsView.Graph:
                return <Graph />
        }
    }

    function individualViewInput(view: StatsView) {
        return (
            <label key={view}>
                <input type="radio" name="view" value={view} checked={view === currentView} onChange={e => setView(view)} />
                {svgForView(view)}
            </label>
        );
    }

    return (
        <div>
            { [StatsView.Graph, StatsView.Grid].map(individualViewInput) }
        </div>
    )
}