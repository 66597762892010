import './Ad.css'
import gatewatch from '../assets/img/ad.jpeg'
import mord from '../assets/img/mord.png'

interface AdProps {
}


export function Ad(adProps: AdProps) {
    const displayMord = Math.random() < 0.5;
    if (displayMord) return (<img className="Ad" src={mord} alt="Protection you can afford, for the right price!" />);
    return <img className="Ad" src={gatewatch} alt="Join your local Gatewatch Chapter today!" />
}