import './Matchup.css'
import { Await, useLoaderData } from 'react-router-dom';
import { Matchup } from '../components/api/types';
import { MatchupCard } from '../components/MatchupCard';
import { useState } from 'react';
import { postMatchupAction } from "../components/api/api";
import React from 'react';
import { Bars } from 'react-loader-spinner';


export function ErrorDisplay() {
  return (
    <>
      <p>Oh no! Something went wrong. 💀</p>
      <p>Try refreshing the page as quickly as you can. If that doesn't work, try stepping outside and shaking your fist at the sun. 🌞</p>
    </>
  )
}


export function LoadingBlocker() {
  return (
    <Bars
      height="100"
      width="100"
      color="#000000"
      ariaLabel="bars-loading"
      wrapperStyle={{}}
      wrapperClass=""
    />
  )
}


export function MatchupRoute() {
  const data = useLoaderData() as { matchup: Matchup };

  return (
    <React.Suspense fallback={<LoadingBlocker />}>
      <Await resolve={data.matchup} errorElement={<ErrorDisplay />}>
        {matchup => <CardGallery initialMatchup={matchup}/>}
      </Await>
    </React.Suspense>
  )
}

function CardGallery({ initialMatchup }: {initialMatchup: Matchup}) {
  const [matchup, setMatchup] = useState(initialMatchup);
  const [isMatchupLoading, setLoading] = useState(false);
  const [imageLoadingStatuses, setImageLoadingStatuses] = useState(
    initialMatchup.cards.map(() => false)
  );
  const [isError, setError] = useState(false);

  const handleImageLoad = (index: number) => {
    setImageLoadingStatuses((prevStatus) => {
      const newStatus = [...prevStatus];
      newStatus[index] = true;
      return newStatus;
    });
  };

  const allImagesLoaded = imageLoadingStatuses.every(Boolean);

  async function submitMatchup(target: { getAttribute: (name: string) => string; }) {
    setLoading(true);
    try {
      const resp = await postMatchupAction({
        winnerId: target.getAttribute("data-id"),
        matchupId: target.getAttribute("data-matchup-id"),
      });
      const matchup = resp as Matchup;
      setMatchup(matchup);
      setImageLoadingStatuses(matchup.cards.map(() => false));
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  if (isError) {
    return (
      <ErrorDisplay />
    )
  } else {
    return (
      <div>
        <div>{(isMatchupLoading || !allImagesLoaded) && <LoadingBlocker /> }</div>
        <div className="Card-gallery" style={{ display: !isMatchupLoading && allImagesLoaded ? 'block' : 'none' }}>
          <ul>
            {matchup.cards.map((card, index) => <MatchupCard onLoad={() => handleImageLoad(index)} onClick={e => submitMatchup(e.currentTarget)} matchupId={matchup.id} key={card.id} card={card} />)}
          </ul>
        </div>
      </div>
    )
  }
}