import { MatchupCard } from "../MatchupCard";
import { Stats, MatchupCard as ApiCard } from "../../components/api/types";

export function GridView({ stats, color }: { stats: Stats, color: string }) {
  // TODO consider how best to DRY this up
  function cards(colorIdentity: string): ApiCard[] {
    const colorStatistics = stats.stats.find(es => es.color === colorIdentity);
    if (!colorStatistics) {
      throw new Error("die!")
    }

    const ids = colorStatistics.scores.map(s => s.id)

    return ids.map(id => {
      const cardById = stats.cards.find(c => c.id === id)
      if (!cardById) {
        // backend's fault for providing a card id but not its metadata 
        throw new Error("die!")
      }
      return cardById;
    });
  }

  return (
    <div className="Card-gallery">
      <ul>
        {cards(color).map((card, _) => <MatchupCard onLoad={() => undefined} matchupId={null} key={card.id} card={card} onClick={_ => undefined} />)}
      </ul>
    </div>
  );
}