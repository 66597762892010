import { MatchupCard as ApiCard } from '../components/api/types';
import { Flag } from './Flag';
import { Tooltip } from "react-tooltip";


interface MatchupCardProps {
    matchupId: string | null
    card: ApiCard
    onClick: (e: any) => void
    onLoad: () => void
}

export function MatchupCard(props: MatchupCardProps) {
    const flagCode = props.card.flagCode;
    const language = props.card.language;

    const tooltip = `${props.card.set} – #${props.card.collectorNumber}`

    return (
        <li className="Matchup-card">
            <img className="Card" onLoad={props.onLoad} onClick={props.onClick} src={props.card.imageUrl} alt={tooltip} data-id={props.card.id} data-matchup-id={props.matchupId} />
            <div className="Matchup-card-data">
                <div data-tooltip-id={props.card.id + '-detail'}>
                    <span className="Matchup-card-data-info">ⓘ</span>
                    <span>{props.card.artist}</span>
                </div>
                <Flag flagCode={flagCode} language={language} />
                <Tooltip id={props.card.id + '-detail'} place="left" variant="info" className="Matchup-card-data-tooltip" style={{ backgroundColor: "#000", color: "#fff" }}>
                    <span>{tooltip}</span>
                </Tooltip>
            </div>
        </li>
    );
}
